<template>
  <div>
    <div style="margin-bottom:20px;">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>自定义组合推荐</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <!-- <el-card shadow="always">
      <div class="title">信息筛选</div>
      <div class="search-content">
        <div style="display:flex;">
          <div style="display:flex;align-items:center;">
            <div style="width:150px;">股票名称/代码</div>
            <el-input v-model.trim="policyTitle" placeholder="请输入股票名称/代码" />
          </div>
          <div style="display:flex;align-items:center;margin-left:80px;">
            <div style="width:75px;">添加日期</div>
            <el-date-picker value-format="YYYY-MM-DD" v-model="publishDate" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
            </el-date-picker>
          </div>
        </div>
        <div class="search-btn" @click="search">筛选</div>
      </div>
    </el-card> -->
    <el-card shadow="always" class="list-container">
      <div class="header">
        <div class="header__title">自定义组合列表</div>
        <div class="header__btn" @click="addGroup">+ 新增自定义组合</div>
      </div>
      <div class="content">
        <el-table :data="tableData" :header-cell-style="{'text-align': 'center','font-weight': 'bold',color: '#333'}" :cell-style="{'text-align': 'center',color: '#333'}" style="width: 100%">
          <el-table-column type="index" label="序号" width="120" />
          <el-table-column prop="groupRemark" label="组合备注" />
          <el-table-column prop="mainLineLabel" label="组合类型" />
          <el-table-column prop="createTime" label="添加时间" />
          <el-table-column prop="effectiveDate" label="起始生效日期" />
          <el-table-column prop="createMan" label="添加人" />
          <el-table-column label="操作">
            <template #default="scope">
              <el-button @click="showDetail(scope.row)" type="text" size="small">查看</el-button>
              <el-button @click="delData(scope.row)" type="text" size="small">删除</el-button>
            </template>
          </el-table-column>
        </el-table>

        <div class="page">
          <el-pagination v-model:currentPage="currentPage" :page-sizes="[5, 10, 20, 50]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total" @size-change="handleSizeChange" @current-change="handleCurrentChange">
          </el-pagination>
        </div>
      </div>
    </el-card>
    <el-dialog :title="dialogTitle" v-model="dialogVisible" :width="720">
      <el-form ref="form" :rules='rules' :model="stockForm" v-if="dialogTitle!=='查看自定义组合'" label-width="180px" label-position="left" class="stock-form">
        <el-form-item label="组合备注" prop='groupRemark'>
          <el-input v-model.trim="stockForm.groupRemark" maxlength="20" style="width: 280px"></el-input>
        </el-form-item>
        <el-form-item label="组合股票(名称)" prop='stockInfo'>
          <el-input class="input" v-for="(item,index) in stockForm.stockInfo" :key="index" v-model.trim="item.stockName" :placeholder="'股票'+(index+1)" maxlength="10" style="width: 280px;"></el-input>
        </el-form-item>
        <el-form-item label="组合类型" prop='mainLineLabel'>
          <el-select v-model="stockForm.mainLineLabel" filterable placeholder="请选择组合" style="width: 270px">
            <el-option v-for="item in stockForm.groupTypeList" :key="item.value" :label="item.msg" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="组合周期标签" prop='periodLabel'>
          <el-select v-model="stockForm.periodLabel" filterable placeholder="请选择组合周期标签" style="width: 270px">
            <el-option v-for="item in stockForm.groupCycleTagList" :key="item.value" :label="item.msg" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <el-form v-else label-width="180px" label-position="left" class="stock-form">
        <el-form-item label="组合备注" prop='groupRemark'>
          <div>{{stockForm.groupRemark}}</div>
        </el-form-item>
        <el-form-item label="组合股票(名称)" prop='stockInfo'>
          <div>
            <div v-for="(item,index) in stockForm.stockInfo" :key="index">
              <span style="marginRight:20px">{{item.stockName}}</span>
              <span>{{item.stockCode}}</span>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="组合类型" prop='mainLineLabel'>
          <div>{{stockForm.mainLineLabel}}</div>

        </el-form-item>
        <el-form-item label="组合周期标签" prop='periodLabel'>
          <div>{{stockForm.periodLabel}}</div>

        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer" v-if="dialogTitle!=='查看自定义组合'">
          <el-button type="info" @click="confirm" style="background-color:#38a28a">提 交</el-button>
          <el-button @click="dialogVisible = false">取 消</el-button>
        </span>
        <span class="dialog-footer" v-else>
          <el-button type="info" @click="editForm" style="background-color:#e99d42;color:#fff;border:none">编 辑</el-button>
          <el-button @click="dialogVisible = false">取 消</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { reactive, toRefs, getCurrentInstance, onMounted, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { ElMessage, ElMessageBox } from "element-plus";
export default {
  setup() {
    const { proxy } = getCurrentInstance();
    const router = useRouter();
    const store = useStore();
    const form = ref();
    const state = reactive({
      policyTitle: '',
      publishDate: [],
      currentPage: 1,
      pageSize: 10,
      tableData: [],
      total: 0,
      dialogVisible: false,
      stockForm: {
        groupRemark: '',
        mainLineLabel: '',
        groupTypeList: [],
        periodLabel: '',
        groupCycleTagList: [],
        stockInfo: [{ stockName: '', stockCode: '' }, { stockName: '', stockCode: '' }, { stockName: '', stockCode: '' }, { stockName: '', stockCode: '' }, { stockName: '', stockCode: '' }],
      },

      dialogTitle: '新增自定义组合',
      groupId: '',
      rules: {
        groupRemark: [
          { required: true, message: "请输入组合备注", trigger: "blur" },
        ],
        mainLineLabel: [
          { required: true, message: "请选择组合类型", trigger: "blur" },
        ],
        periodLabel: [
          { required: true, message: "请选择组合周期标签", trigger: "blur" },
        ],
        stockInfo: [
          { type: 'array', required: true, message: '请至少添加一支股票', trigger: 'change' }
        ],
      },
    })
    watch(
      () => state.dialogVisible,
      (val) => {
        if (!val && state.dialogTitle !== '查看自定义组合') {
          form.value.resetFields();
        }
      }
    );
    onMounted(() => {
      getList()
      getGroupType()
      getGroupLabel()
    })

    const search = () => {
      getList()
    }

    // 组合类型
    const getGroupType = () => {
      proxy.$get('/cts/back/digitalGroup/groupType').then((res) => {
        if (res.resultState === '200') {
          state.stockForm.groupTypeList = res.data;
        }
      }).catch((err) => {

      });
    }
    // 周期标签
    const getGroupLabel = () => {
      proxy.$get('/cts/back/digitalGroup/groupLabel').then((res) => {
        if (res.resultState === '200') {
          state.stockForm.groupCycleTagList = res.data;
        }
      }).catch((err) => {

      });
    }
    const getList = () => {
      let data = {
        content: {
          endDate: "",
          groupId: "",
          startDate: ""
        },
        field: "",
        flag: "",
        pageNo: state.currentPage,
        pageSize: state.pageSize,
        sequence: true,
        stockCodes: []
      }
      proxy.$post('/cts/back/digitalGroup/getList', data).then((res) => {
        if (res.resultState === '200') {
          state.tableData = res.data.list;
          state.total = res.data.total;
        }
      }).catch((err) => {

      });
    }

    const handleSizeChange = (val) => {
      state.pageSize = val;
      getList();
    }

    const handleCurrentChange = (val) => {
      state.currentPage = val;
      getList();
    }

    const confirm = () => {
      form.value.validate((valid) => {
        let groupStock = []
        let isNull = state.stockForm.stockInfo.every((item) => {
          return !item.stockName
        })
        if (isNull) {
          ElMessage({
            message: '请输入股票代码',
            type: "error",
          });
          return
        } else {
          // state.stockForm.stockInfo = state.stockForm.stockInfo.filter((item) => {
          //   return item.stockName
          // })
          state.stockForm.stockInfo.forEach(item => {
            if (item.stockName) groupStock.push(item.stockName)
          })
        }
        if (valid) {
          let data = {
            groupId: state.dialogTitle.includes('新增') ? "" : state.groupId,
            groupRemark: state.stockForm.groupRemark,
            groupStock: groupStock,
            mainLineLabel: state.stockForm.mainLineLabel,
            periodLabel: state.stockForm.periodLabel
          }
          let url = state.dialogTitle.includes('新增') ? '/cts/back/digitalGroup/addGroup' : '/cts/back/digitalGroup/updateGroup'
          proxy.$post(url, data).then((res) => {
            if (res.resultState === '200') {
              state.dialogVisible = false
              getList()
            }
          }).catch((err) => {

          });
        }
        // dialogTitle!=='查看自定义组合'
      });
    }
    const addGroup = () => {
      state.dialogVisible = true
      state.dialogTitle = '新增自定义组合'
      state.stockForm.groupRemark = ''
      state.stockForm.mainLineLabel = ''
      state.stockForm.periodLabel = ''
      state.stockForm.stockInfo = []
      for (let index = 0; index < 5; index++) {
        let item = { stockName: '', stockCode: '' }
        state.stockForm.stockInfo.push(item)
      }
    }

    const showDetail = (data) => {
      state.groupId = data.groupId
      state.dialogVisible = true
      state.dialogTitle = '查看自定义组合'
      getDetail()
    }
    // 删除数据
    const delData = (data) => {
      ElMessageBox.alert("确认删除该条数据吗?", "提示", {
        confirmButtonText: "确定",
        type: "warning",
      })
        .then(() => {
          let params = `?groupId=${data.groupId}`
          proxy.$get('/cts/back/digitalGroup/deleteGroup' + params).then((res) => {
            if (res.resultState === '200') {
              getList()
            }
          }).catch((err) => {

          });
        })
        .catch(() => { });

    }
    const getDetail = (item) => {
      let params = `?groupId=${state.groupId}`
      proxy.$get('/cts/back/digitalGroup/getGroup' + params).then((res) => {
        if (res.resultState === '200') {
          const { groupRemark, mainLineLabel, periodLabel, stockInfo } = res.data
          state.stockForm.groupRemark = groupRemark
          state.stockForm.mainLineLabel = mainLineLabel
          state.stockForm.periodLabel = periodLabel
          state.stockForm.stockInfo = stockInfo
          if (item) {
            let length = state.stockForm.stockInfo.length
            let num = 5 - length
            if (num > 0) {
              for (let index = 0; index < num; index++) {
                let item = { stockName: '', stockCode: '' }
                state.stockForm.stockInfo.push(item)
              }
            }
          }
        }
      }).catch((err) => {

      });
    }

    const editForm = () => {
      state.dialogVisible = true
      state.dialogTitle = '编辑自定义组合'
      getDetail('edit')
    }
    const formatterTime = (row, column) => {
      let time = row.releaseDate;
      let month = '', date = '';
      if (time[1] < 10) {
        month = `0${time[1]}`
      } else {
        month = `${time[1]}`
      }
      if (time[2] < 10) {
        date = `0${time[2]}`
      } else {
        date = `${time[2]}`
      }

      return `${time[0]}-${month}-${date}`
    }

    return {
      ...toRefs(state),
      search,
      handleSizeChange,
      handleCurrentChange,
      addGroup,
      showDetail,
      formatterTime,
      getGroupType,
      editForm,
      form,
      confirm,
      delData
    }
  }
}
</script>

<style lang="scss" scoped>
.container-title {
  font-size: 16px;
  font-weight: bold;
  margin: 10px 0 20px 0;
}
.title {
  font-weight: bold;
}
.search-content {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .search-box {
    display: flex;
    align-items: center;
    &__title {
      width: 130px;
    }
  }
  .search-btn {
    background: rgb(56, 162, 138);
    width: 80px;
    padding: 8px 0;
    border-radius: 6px;
    text-align: center;
    color: #fff;
    cursor: pointer;
    font-weight: bold;
  }
}
.list-container {
  margin-top: 20px;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
    font-weight: bold;
    // &__title {
    //   font-weight: bold;
    // }
    &__btn {
      background: rgb(56, 162, 138);
      margin-left: 40px;
      padding: 8px;
      border-radius: 6px;
      text-align: center;
      color: #fff;
      cursor: pointer;
    }
  }
  .content {
    margin-top: 15px;
    .page {
      float: right;
      margin: 20px;
    }
  }
}
.stock-form {
  .input {
    margin-bottom: 20px;
    &:last-child {
      margin: 0;
    }
  }
}
::v-deep .el-dialog__footer {
  text-align: left;
}
</style>